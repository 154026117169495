import React, { useState, useEffect } from 'react';
import { EntryTypeFields } from './EntryFields';
import CustomModal from './Modal';
import { useDispatch } from 'react-redux';
import {
  UPDATE_ASSET,
  UPDATE_LIABILITY,
  UPDATE_INCEXP,
  DELETE_ASSET,
  DELETE_LIABILITY,
  DELETE_INCEXP,
  ASSET,
  LIABILITY,
  INCEXP,
} from '../actions/types';

const EntryModal = ({ item, type, closeModal }) => {
  const dispatch = useDispatch();
  const [formData, updateFormData] = useState(item);
  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const updateEntry = () => {
    const payload = formData;

    switch (type) {
      case ASSET:
        dispatch({ type: UPDATE_ASSET, payload });
        break;
      case LIABILITY:
        dispatch({ type: UPDATE_LIABILITY, payload });
        break;
      case INCEXP:
        dispatch({ type: UPDATE_INCEXP, payload });
        break;
      default:
        console.log('Invalid entry type to update');
        break;
    }
    closeModal();
  };

  const deleteEntry = () => {
    const payload = formData;
    switch (type) {
      case ASSET:
        dispatch({
          type: DELETE_ASSET,
          payload,
        });
        break;
      case LIABILITY:
        dispatch({
          type: DELETE_LIABILITY,
          payload,
        });
        break;
      case INCEXP:
        dispatch({
          type: DELETE_INCEXP,
          payload,
        });
        break;
      default:
        console.log('Invalid entry type to delete');
        break;
    }
    closeModal();
  };

  const handleKeyDown = (event) => {
    const { code } = event;
    switch (code) {
      case 'Escape':
        closeModal();
        break;
      case 'Enter':
        updateEntry();
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [formData]);

  return (
    <CustomModal closeModal={closeModal}>
      <button
        className="bg-gray-100 border rounded-md p-2 items-center justify-center text-gray-400 hover:text-gray-600 hover:bg-gray-200 absolute top-0 right-1 m-3"
        onClick={closeModal}
      >
        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <EntryTypeFields item={item} type={type} handleChange={handleChange} />
      <div className="flex justify-center mt-2">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded p-1 m-2 w-24"
          onClick={updateEntry}
        >
          Update
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded p-1 m-2 w-24"
          onClick={deleteEntry}
        >
          Delete 🗑️
        </button>
      </div>
      {/* <pre className="bg-gray-200">{JSON.stringify(formData, undefined, 2)}</pre> */}
    </CustomModal>
  );
};
export const Entry = ({ item, type }) => {
  const { name, amount, frequency } = item;
  // For the modal button
  const [isHidden, setIsHidden] = useState(true);
  const openModal = () => {
    setIsHidden(false);
  };
  const closeModal = () => {
    setIsHidden(true);
  };

  const frequencyFunction = {
    365: 'Daily',
    52: 'Weekly',
    26: 'Biweekly',
    24: 'Semi-monthly',
    12: 'Monthly',
    4: 'Quarterly',
    1: 'Annually',
    0: 'One-time',
  };

  const smFrequencyFunction = {
    365: '/day',
    52: '/wk',
    26: '/2wk',
    24: '2x/mth',
    12: '/mth',
    4: '/3mth',
    1: '/yr',
    0: 'Once',
  };

  return (
    <>
      <div className="grid grid-cols-6 border-2 hover:bg-blue-100 rounded-xl p-1" onClick={openModal}>
        <div className="col-span-4 truncate sm:pl-1">{name}</div>
        <div className="md:text-base text-sm flex items-center">{new Intl.NumberFormat().format(amount)}</div>
        <div className="sm:hidden text-xs flex">
          <p className="place-self-center text-right w-full">{type === INCEXP && smFrequencyFunction[frequency]}</p>
        </div>
        <div className="hidden sm:block md:text-base text-sm truncate">
          {type === INCEXP && frequencyFunction[frequency]}
        </div>
      </div>

      {!isHidden && <EntryModal item={item} type={type} closeModal={closeModal} />}
    </>
  );
};
