import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Carousel, { CarouselItem } from '../components/Carousel';
import TopBar from '../components/TopBar';
// import Mobile from '../img/Mobile.png';
import Desktop from '../img/Desktop.png';
import freshgrad from '../img/freshgrad.png';
import parent from '../img/parent.png';
import retiree from '../img/retiree.png';

const samples = [
  {
    url: 'profile/freshgrad',
    name: 'Fresh graduate',
    description: "I'm a new graduate. See my financial story as I go through multiple jobs and buy my first property!",
    img: freshgrad,
    col: 'green-400',
  },
  {
    url: 'profile/newparent',
    name: 'New parent',
    description: "I'm a new parent. See my financial story as I manage expenses of my first child!",
    img: parent,
    col: 'sky-300',
  },
  {
    url: 'profile/retiredcouple',
    name: 'Retired Couple',
    description: "We're nearing retirement. See our financial story as we retire and use our savings!",
    img: retiree,
    col: 'fuchsia-400',
  },
];

const Home = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <div className="flex flex-col h-screen">
      <div className="container mx-auto mt-1">
        <TopBar />
        <main className="grid grid-cols-2">
          <div className="col-span-2 md:col-span-1 text-center md:text-left h-[50vh] flex flex-col justify-center">
            <h1 className="text-5xl font-bold tracking-wide">My Finance Story</h1>
            {!isAuthenticated && (
              <div className="pt-5 hidden md:block ">
                <Link
                  to="/signup"
                  className="text-center border-none rounded-full px-3 py-2 bg-slate-900 text-blue-100 transition hover:bg-sky-300 hover:bg-gradient-to-r hover:from-blue-700 hover:text-white duration-500"
                >
                  Sign up
                </Link>
                <Link
                  to="/login"
                  className="text-center rounded-full px-3 py-2 transition hover:bg-sky-300 hover:bg-gradient-to-r hover:from-blue-700 hover:text-white duration-500"
                >
                  Login
                </Link>
              </div>
            )}
          </div>
          <div className="md:col-span-1 col-span-2 m-1 flex items-center justify-center">
            <div className="relative group">
              <div className="absolute hidden md:block -inset-2 bg-gradient-to-r from-blue-700 to-sky-300 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
              <img src={Desktop} className="relative hidden md:block h-64 rounded-lg" />
              {/* <img src={Mobile} className="md:hidden h-56 w" /> */}
            </div>
          </div>
        </main>
        <div id="benefits" className="grid grid-cols-3">
          <div className="md:col-span-1 col-span-3 h-52 min-h-[20vh] m-1 p-3 bg-slate-800 text-blue-100">
            <h2 className="text-4xl py-2">Read</h2>
            <p>Learn how life and choices affects financial outcomes</p>
          </div>
          <div className="md:col-span-1 col-span-3 h-52 min-h-[20vh] m-1 p-3 bg-slate-600 text-blue-100">
            <h2 className="text-4xl py-2">Write</h2>
            <p>Record your financial story, or play make-belief. Anything is possible!</p>
          </div>
          <div className="md:col-span-1 col-span-3 h-52 min-h-[20vh] m-1 p-3 bg-slate-400 text-blue-50">
            <h2 className="text-4xl py-2">Share</h2>
            <p>Looking for advice? Or want to help others? Share your story with others!</p>
          </div>
        </div>
        <Carousel autoSlide={true} autoSlideInterval={3000}>
          {samples.map((s) => (
            <CarouselItem key={s.url}>
              {/* <div>sample profile</div> */}
              <div className="w-full h-48 m-1 p-3 md:p-6 flex border-2 rounded-sm">
                <img
                  className="w-24 h-24 md:w-32 md:h-32 p-1 mt-8 md:mt-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                  src={s.img}
                  alt="Bordered avatar"
                />
                <Link to={s.url} className="pl-2 md:pl-8 flex flex-col justify-center whitespace-normal bg-gray">
                  <p className="font-bold text-lg md:text-xl">{s.name}</p>
                  <p className="text-sm md:text-base text-justify">{s.description}</p>
                </Link>
              </div>
            </CarouselItem>
          ))}
        </Carousel>
      </div>
      <div id="info" className="w-screen flex flex-grow bg-slate-800 text-blue-100">
        <div className="container mx-auto m-1">
          <Link className="h-fit m-1" to="/terms">
            Terms of Service
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
