import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../actions/auth';
import { SIGNUP_FAIL, SIGNUP_SUCCESS } from '../actions/types';
import axios from 'axios';
import { Switch } from '@headlessui/react';
import { load_assets, load_liabilities, load_incexp, save } from '../actions/auth';
import TopBar from '../components/TopBar';
import HomeBar from '../components/HomeBar';
import { Entry } from '../components/Entry';
import { ASSET, LIABILITY, INCEXP } from '../actions/types';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Signup = () => {
  // eslint-disable-next-line no-unused-vars
  let assets = useSelector((state) => state.auth.assets);
  // eslint-disable-next-line no-unused-vars
  let liabilities = useSelector((state) => state.auth.liabilities);
  // eslint-disable-next-line no-unused-vars
  let incexp = useSelector((state) => state.auth.incexp);

  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState(null);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    re_password: '',
  });

  const [shouldPrepopulate, setShouldPrepopulate] = useState(false);

  const { username, email, password, re_password } = formData;
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password === re_password) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const body = JSON.stringify({
        username,
        email,
        password,
        re_password,
      });
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/`, body, config);
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: res.data,
        });
        const userid = res.data.id;

        dispatch(login(email, password)).then(() => {
          if (shouldPrepopulate) {
            assets = assets.map((o) => {
              return { user: userid, ...o };
            });
            liabilities = liabilities.map((o) => {
              return { user: userid, ...o };
            });
            incexp = incexp.map((o) => {
              return { user: userid, ...o };
            });
            const config = {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('access')}`,
                Accept: 'application/json',
              },
            };
            const assetPromise = axios.post(
              `${process.env.REACT_APP_API_URL}/app/asset/`,
              { create: assets, update: [], delete: [] },
              config
            );

            const liabilityPromise = axios.post(
              `${process.env.REACT_APP_API_URL}/app/liability/`,
              { create: liabilities, update: [], delete: [] },
              config
            );

            const incexpPromise = axios.post(
              `${process.env.REACT_APP_API_URL}/app/incexp/`,
              { create: incexp, update: [], delete: [] },
              config
            );

            Promise.all([assetPromise, liabilityPromise, incexpPromise]).then(() => {
              dispatch(load_assets(username));
              dispatch(load_liabilities(username));
              dispatch(load_incexp(username));
              dispatch(save());
            });
          }
        });
        setErrMsg('logged in');
      } catch (err) {
        console.log(err);
        dispatch({
          type: SIGNUP_FAIL,
        });
        setErrMsg('username or email already taken');
      }
    } else {
      setErrMsg('passwords do not match');
    }
  };

  return user ? (
    <Redirect to="/profile" />
  ) : (
    <>
      <div className="grid grid-cols-2 bg-gray-100">
        <HomeBar />
        <TopBar />
      </div>
      <div className="container mx-auto mt-5 px-10">
        <h1 className="text-2xl font-bold text-gray-900">Create your account</h1>

        <form className="mt-8" onSubmit={(e) => onSubmit(e)}>
          <div className="relative">
            <input
              id="username"
              name="username"
              type="text"
              placeholder="username"
              onChange={onChange}
              required
              className="peer max-w-xs h-10 w-full border-b-2 border-0 border-gray-300 text-gray-900 placeholder-transparent focus:outline-0 focus:border-blue-600 focus:ring-0"
            />
            <label
              htmlFor="username"
              className="absolute left-0 -top-3.5 text-gray-900 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-900 peer-focus:text-sm"
            >
              Username
            </label>
          </div>
          <div className="mt-8 relative">
            <input
              id="email"
              name="email"
              type="text"
              placeholder="Email@email.com"
              onChange={onChange}
              required
              className="peer max-w-xs h-10 w-full border-b-2 border-0 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-600 focus:ring-0"
            />
            <label
              htmlFor="email"
              className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-900 peer-focus:text-sm"
            >
              Email
            </label>
          </div>
          <div className="mt-8 relative">
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              onChange={onChange}
              required
              className="peer max-w-xs h-10 w-full border-b-2 border-0 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-600 focus:ring-0"
            />
            <label
              htmlFor="password"
              className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-900 peer-focus:text-sm"
            >
              New Password
            </label>
          </div>
          <div className="mt-8 relative">
            <input
              id="re_password"
              name="re_password"
              type="password"
              placeholder="Repassord"
              onChange={onChange}
              required
              className="peer max-w-xs h-10 w-full border-b-2 border-0 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-600 focus:ring-0"
            />
            <label
              htmlFor="re_password"
              className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-900 peer-focus:text-sm"
            >
              Re-enter Password
            </label>
          </div>

          <Switch.Group as="div" className="flex items-center py-6 space-x-4">
            <Switch.Label as="span">
              <span className="text-sm font-medium text-gray-900">Prepopulate Profile</span>
            </Switch.Label>
            <Switch
              checked={shouldPrepopulate}
              onChange={setShouldPrepopulate}
              className={classNames(
                shouldPrepopulate ? 'bg-blue-500' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  shouldPrepopulate ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
              />
            </Switch>
          </Switch.Group>

          <input
            value="Create account"
            type="submit"
            className="mt-6 px-4 py-2 max-w-xs rounded bg-blue-500 hover:bg-blue-400 text-white font-semibold text-center block w-full focus:outline-none focus:ring focus:ring-offset-2 focus:ring-blue-500 focus:ring-opacity-80 cursor-pointer"
          />
        </form>
        <div className="text-rose-600">{errMsg}</div>
        {shouldPrepopulate && (
          <div className="grid grid-cols-2 px-2 py-5">
            <hr className="my-4 col-span-2 bg-gray-200 dark:bg-gray-700" />
            <div className="col-span-2 md:col-span-1">
              <div className="font-bold pt-2"> Asset</div>
              {assets.map((asset) => (
                <Entry key={asset.external_id} item={asset} type={ASSET} />
              ))}
              <div className="font-bold pt-2 pb-1">Liability</div>
              {liabilities.map((liability) => (
                <Entry key={liability.external_id} item={liability} type={LIABILITY} />
              ))}
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="font-bold pt-2"> Income or Expense</div>
              {incexp.map((incexp) => (
                <Entry key={incexp.external_id} item={incexp} type={INCEXP} />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Signup;
