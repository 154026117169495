import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../actions/auth';
import { IoLogOut } from 'react-icons/io5';
import { CgProfile } from 'react-icons/cg';

function TopBar() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const [redirect, setRedirect] = useState(false);

  const logoutUser = () => {
    dispatch(logout());
    setRedirect(true);
  };

  const guestLinks = () => (
    <div className="pt-1">
      <Link
        to="/signup"
        className="text-center border-none rounded-full px-3 py-2 bg-slate-900 text-blue-100 transition hover:bg-sky-300 hover:bg-gradient-to-r hover:from-blue-700 hover:text-white duration-300"
      >
        Sign up
      </Link>
      <Link
        to="/login"
        className="text-center rounded-full px-3 py-2 transition hover:bg-sky-300 hover:bg-gradient-to-r hover:from-blue-700 hover:text-white duration-300"
      >
        Login
      </Link>
    </div>
  );

  const authLinks = () => (
    <div className="flex space-x-2 sm:space-x-4">
      <Link to="/profile" className="flex align-bottom">
        <CgProfile size="24" />
        <b>{user ? user.username : 'not logged in'}</b>
      </Link>
      <div className="group flex relative">
        <a href="" onClick={logoutUser}>
          <IoLogOut size="25" />
        </a>
        <span
          className="group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left
        -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
        >
          Logout
        </span>
      </div>
    </div>
  );

  return (
    <nav className="flex flex-row-reverse bg-transparent pr-2 py-1 my-1 align-middle">
      <div className="text-sm md:text-base">{isAuthenticated ? authLinks() : guestLinks()}</div>
      {redirect && <Redirect to="/" />}
    </nav>
  );
}

export default TopBar;
