import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password } from '../actions/auth';
import TopBar from '../components/TopBar';
import HomeBar from '../components/HomeBar';

const ResetPassword = ({ reset_password }) => {
  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
  });

  const { email } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    reset_password(email);
    setRequestSent(true);
  };

  if (requestSent) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <div className="grid grid-cols-2 bg-gray-100">
        <HomeBar />
        <TopBar />
      </div>
      <div className="container mx-auto mt-5 px-10">
        <h1 className="text-2xl font-bold text-gray-900">Password Reset</h1>
        <form className="mt-8" onSubmit={(e) => onSubmit(e)}>
          <div className="relative">
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Email@email.com"
              value={email}
              onChange={(e) => onChange(e)}
              required
              className="peer max-w-xs h-10 w-full border-b-2 border-0 border-gray-300 text-gray-900 placeholder-transparent focus:outline-0 focus:border-blue-600 focus:ring-0"
            />
            <label
              htmlFor="email"
              className="absolute left-0 -top-3.5 text-gray-900 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-900 peer-focus:text-sm"
            >
              Email address
            </label>
          </div>
          <input
            value="Send reset email"
            type="submit"
            className="mt-10 px-4 py-2 max-w-xs rounded bg-blue-500 hover:bg-blue-400 text-white font-semibold text-center block w-full focus:outline-none focus:ring focus:ring-offset-2 focus:ring-blue-500 focus:ring-opacity-80 cursor-pointer"
          />
        </form>
      </div>
    </>
  );
};

export default connect(null, { reset_password })(ResetPassword);
