export const ASSET = 'asset';
export const LIABILITY = 'liability';
export const INCEXP = 'incexp';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const ASSETS_LOADED_SUCCESS = 'ASSETS_LOADED_SUCCCESS';
export const ASSETS_LOADED_FAIL = 'ASSETS_LOADED_FAIL';
export const LIABILITIES_LOADED_SUCCESS = 'LIABILITIES_LOADED_SUCCESS';
export const LIABILITIES_LOADED_FAIL = 'LIABILITIES_LOADED_FAIL';
export const INCEXP_LOADED_SUCCESS = 'INCEXP_LOADED_SUCCESS';
export const INCEXP_LOADED_FAIL = 'INCEXP_LOADED_FAIL';
export const ADD_ASSET = 'ADD_ASSET';
export const ADD_LIABILITY = 'ADD_LIABILITY';
export const ADD_INCEXP = 'ADD_INCEXP';
export const UPDATE_ASSET = 'UPDATE_ASSET';
export const UPDATE_LIABILITY = 'UPDATE_LIABILITY';
export const UPDATE_INCEXP = 'UPDATE_INCEXP';
export const DELETE_ASSET = 'DELETE_ASSET';
export const DELETE_LIABILITY = 'DELETE_LIABILITY';
export const DELETE_INCEXP = 'DELETE_INCEXP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS';
export const GOOGLE_AUTH_FAIL = 'GOOGLE_AUTH_FAIL';
export const FACEBOOK_AUTH_SUCCESS = 'FACEBOOK_AUTH_SUCCESS';
export const FACEBOOK_AUTH_FAIL = 'FACEBOOK_AUTH_FAIL';
export const LOGOUT = 'LOGOUT';
export const DELETED = 'DELETED';
export const MODIFIED = 'MODIFIED';
export const CREATED = 'CREATED';
export const SAVE_TO_BACKEND = 'SAVE_TO_BACKEND';
export const VIEW_USER = 'VIEW_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
