import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { load_user, reset_password } from '../actions/auth';
import { useDispatch } from 'react-redux';
import CustomModal from '../components/Modal';
import { UsernameField, EmailField, CurrentPasswordField, ToggleProfileAccessField } from '../components/ProfileFields';
import TopBar from '../components/TopBar';
import HomeBar from '../components/HomeBar';
import { MdOutlineEmail } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';

const UserProfileModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [userData, updateUserData] = useState(user);
  const [errMsg, setErrMsg] = useState(null);
  const handleChange = (e) => {
    updateUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const updateUserProfile = async () => {
    const { username, email, current_password: password, is_public } = userData;

    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${localStorage.getItem('access')}`,
          Accept: 'application/json',
        },
      };

      const body = JSON.stringify({ username, email, password, is_public });
      try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/auth/users/me/`, body, config);
        if (res.status == 200) {
          setErrMsg(null);
          dispatch(load_user());
          closeModal();
        }
      } catch (err) {
        if (
          Object.prototype.hasOwnProperty.call(err.response.data, 'password') |
          Object.prototype.hasOwnProperty.call(err.response.data, 'authorize')
        ) {
          setErrMsg('Invalid password');
        } else if (err.response.status == 400) {
          setErrMsg(Object.values(err.response.data).join('\n'));
        } else {
          setErrMsg('Invalid password');
        }
      }
    }
  };

  const handleKeyDown = (event) => {
    const { code } = event;
    switch (code) {
      case 'Escape':
        closeModal();
        break;
      case 'Enter':
        updateUserProfile();
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [userData]);

  return (
    <CustomModal closeModal={closeModal}>
      <button
        className="bg-gray-100 border rounded-md p-2 items-center justify-center text-gray-400 hover:text-gray-600 hover:bg-gray-200 absolute top-0 right-1 m-1"
        onClick={closeModal}
      >
        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <UsernameField defaultValue={user.username} handleChange={handleChange} />
      <EmailField defaultValue={user.email} handleChange={handleChange} />
      <CurrentPasswordField handleChange={handleChange} />
      <ToggleProfileAccessField defaultValue={user.is_public} handleChange={handleChange} />
      <button
        className="col-span-12 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded "
        onClick={updateUserProfile}
      >
        Save Profile
      </button>
      <div className="text-rose-600 whitespace-pre">{errMsg}</div>
    </CustomModal>
  );
};

const Profile = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const viewed_user = useSelector((state) => state.auth.viewed_user);
  const [requestSent, setRequestSent] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(reset_password(user.email));
    setRequestSent(true);
  };

  const [isModalHidden, setIsModalHidden] = useState(true);
  const openModal = () => {
    setIsModalHidden(false);
  };
  const closeModal = () => {
    setIsModalHidden(true);
  };

  if (isAuthenticated && user) {
    return (
      <>
        <div className="grid grid-cols-2 bg-gray-100">
          <HomeBar />
          <TopBar />
        </div>
        <div className="container mx-auto mt-5 px-2">
          {!isModalHidden && <UserProfileModal closeModal={closeModal} />}
          <h2 className="flex">
            <CgProfile size="20" />
            {user.username}
          </h2>
          <h2 className="flex">
            <MdOutlineEmail size="20" />
            {user.email}
          </h2>
          <h2 className="pb-4">Last viewed profile: {viewed_user}</h2>
          <Link to={`profile/${user.username}/journal`} className="p-2 hover:bg-gray-100 border-2 rounded-full">
            My journal
          </Link>
          <Link to={`profile/${user.username}`} className="border-2 p-2  hover:bg-gray-100 rounded-full">
            My profile page
          </Link>
          <div className="grid pt-2">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2 w-40"
              onClick={openModal}
            >
              Edit Profile
            </button>
            {!requestSent && (
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2 w-40"
                onClick={onSubmit}
                // disabled='true'
              >
                Password Reset
              </button>
            )}
          </div>
          {requestSent && (
            <div className="text-rose-600">Password reset email sent. Please check your email for instructions.</div>
          )}
        </div>
      </>
    );
  } else if (isAuthenticated === false) {
    return <Redirect to="/signup" />;
  } else {
    return <div>loading</div>;
  }
};

export default Profile;
