import React, { useState, useEffect } from 'react';
import CustomModal from './Modal';
import { SelectEntryTypeField, EntryTypeFields } from './EntryFields';
import { useDispatch } from 'react-redux';
import { ADD_ASSET, ADD_LIABILITY, ADD_INCEXP, ASSET, LIABILITY, INCEXP } from '../actions/types';

// For new entry

export const NewEntryModal = ({ nextIndex, closeModal }) => {
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState();
  const [entryType, setEntryType] = useState('');

  const [formData, updateFormData] = useState({
    external_id: nextIndex + 1,
    entryType: entryType,
    name: '',
    amount: '',
    frequency: 0,
    interest: 0,
  });
  const handleChange = (e) => {
    updateFormData({ ...formData, [e.target.name]: e.target.value });
    setErrMsg('');
  };

  const handleSubmit = () => {
    const payload = formData;
    if (entryType == '') {
      setErrMsg('Select an entry type');
    } else if (formData.name.length > 0) {
      switch (entryType) {
        case ASSET:
          dispatch({ type: ADD_ASSET, payload });
          break;
        case LIABILITY:
          dispatch({ type: ADD_LIABILITY, payload });
          break;
        case INCEXP:
          dispatch({ type: ADD_INCEXP, payload });
          break;
        default:
          break;
      }
      closeModal();
    } else {
      setErrMsg('Enter an entry name');
    }
  };

  const setDisplayedEntryType = (e) => {
    const displayedEntryType = e.target.value;
    setEntryType(displayedEntryType);
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleKeyDown = (event) => {
    const { code } = event;
    switch (code) {
      case 'Escape':
        closeModal();
        break;
      case 'Enter':
        handleSubmit();
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [entryType, formData]);

  return (
    <CustomModal closeModal={closeModal}>
      <SelectEntryTypeField setDisplayedEntryType={setDisplayedEntryType} />
      <EntryTypeFields item={formData} type={entryType} handleChange={handleChange} />
      <div className="flex justify-center">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded p-1 m-2 w-24"
          onClick={handleSubmit}
        >
          Add entry
        </button>
        <button
          className="bg-gray-100 border rounded-md p-2 items-center justify-center text-gray-400 hover:text-gray-600 hover:bg-gray-200 absolute top-0 right-0 m-3"
          onClick={closeModal}
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      {/* <pre className="bg-gray-200">{JSON.stringify(formData, null, 2)}</pre> */}
      <h2 className="text-rose-600 w-full text-center">{errMsg}</h2>
    </CustomModal>
  );
};

export default NewEntryModal;
