import React from 'react';
const BottomBar = ({ NetWorth, CF }) => {
  return (
    <footer className="grid grid-cols-2 text-center fixed inset-x-0 bottom-0 w-screen bg-gray-200">
      {/* on tablet and bigger screen sizes, lay out footer horizontally */}
      <div className="sm:col-span-1 col-span-2">Net Worth: {NetWorth.toLocaleString()}</div>
      <div className="sm:col-span-1 col-span-2">Current Annual Cashflow: {CF.toLocaleString()}</div>
    </footer>
  );
};

export default BottomBar;
