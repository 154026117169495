import axios from 'axios';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  ASSETS_LOADED_SUCCESS,
  ASSETS_LOADED_FAIL,
  LIABILITIES_LOADED_SUCCESS,
  LIABILITIES_LOADED_FAIL,
  INCEXP_LOADED_SUCCESS,
  INCEXP_LOADED_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  // SIGNUP_SUCCESS,
  // SIGNUP_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  // GOOGLE_AUTH_SUCCESS,
  // GOOGLE_AUTH_FAIL,
  // FACEBOOK_AUTH_SUCCESS,
  // FACEBOOK_AUTH_FAIL,
  LOGOUT,
  SAVE_TO_BACKEND,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
} from './types';

export const load_assets = (username) => async (dispatch) => {
  const config = localStorage.getItem('access')
    ? {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${localStorage.getItem('access')}`,
          Accept: 'application/json',
        },
        params: { username: username },
      }
    : {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        params: { username: username },
      };
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/app/asset/`, config);
    dispatch({
      type: ASSETS_LOADED_SUCCESS,
      payload: res,
    });
  } catch (err) {
    let res = err.response;
    dispatch({
      type: ASSETS_LOADED_FAIL,
    });
    switch (res.status) {
      case 404:
        window.location.href = '/profile/user_not_found?username='.concat(username);
        break;
    }
  }
};

export const load_liabilities = (username) => async (dispatch) => {
  const config = localStorage.getItem('access')
    ? {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${localStorage.getItem('access')}`,
          Accept: 'application/json',
        },
        params: { username: username },
      }
    : {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        params: { username: username },
      };
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/app/liability/`, config);
    dispatch({
      type: LIABILITIES_LOADED_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LIABILITIES_LOADED_FAIL,
    });
  }
};

export const load_incexp = (username) => async (dispatch) => {
  const config = localStorage.getItem('access')
    ? {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${localStorage.getItem('access')}`,
          Accept: 'application/json',
        },
        params: { username: username },
      }
    : {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        params: { username: username },
      };
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/app/incexp/`, config);
    dispatch({
      type: INCEXP_LOADED_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: INCEXP_LOADED_FAIL,
    });
  }
};

export const load_user = () => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${localStorage.getItem('access')}`,
        Accept: 'application/json',
      },
    };
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/users/me/`, config);
      dispatch({
        type: USER_LOADED_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_LOADED_FAIL,
      });
    }
  } else {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

export const update_user = (username, email, password, is_public) => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${localStorage.getItem('access')}`,
        Accept: 'application/json',
      },
    };

    const body = JSON.stringify({ username, email, password, is_public });
    try {
      const res = await axios.patch(`${process.env.REACT_APP_API_URL}/auth/users/me/`, body, config);

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_USER_FAIL,
      });
    }
  } else {
    dispatch({
      type: UPDATE_USER_FAIL,
    });
  }
};

// export const googleAuthenticate = (state, code) => async (dispatch) => {
//   if (state && code && !localStorage.getItem('access')) {
//     const config = {
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     };

//     const details = {
//       state: state,
//       code: code,
//     };

//     const formBody = Object.keys(details)
//       .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]))
//       .join('&');

//     try {
//       const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?${formBody}`, config);

//       dispatch({
//         type: GOOGLE_AUTH_SUCCESS,
//         payload: res.data,
//       });

//       dispatch(load_user());
//     } catch (err) {
//       dispatch({
//         type: GOOGLE_AUTH_FAIL,
//       });
//     }
//   }
// };

// export const facebookAuthenticate = (state, code) => async (dispatch) => {
//   if (state && code && !localStorage.getItem('access')) {
//     const config = {
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     };

//     const details = {
//       state: state,
//       code: code,
//     };

//     const formBody = Object.keys(details)
//       .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]))
//       .join('&');

//     try {
//       const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/o/facebook/?${formBody}`, config);

//       dispatch({
//         type: FACEBOOK_AUTH_SUCCESS,
//         payload: res.data,
//       });

//       dispatch(load_user());
//     } catch (err) {
//       dispatch({
//         type: FACEBOOK_AUTH_FAIL,
//       });
//     }
//   }
// };

export const checkAuthenticated = () => async (dispatch) => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    const body = JSON.stringify({ token: localStorage.getItem('access') });

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/verify/`, body, config);

      if (res.data.code !== 'token_not_valid') {
        dispatch({
          type: AUTHENTICATED_SUCCESS,
        });
      } else {
        dispatch({
          type: AUTHENTICATED_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/create/`, body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(load_user());
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// export const signup = (username, email, password, re_password) => async (dispatch) => {
//   const config = {
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   };

//   const body = JSON.stringify({
//     username,
//     email,
//     password,
//     re_password,
//   });
//   try {
//     const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/`, body, config);
//     dispatch({
//       type: SIGNUP_SUCCESS,
//       payload: res.data,
//     });
//   } catch (err) {
//     console.log(err);
//     dispatch({
//       type: SIGNUP_FAIL,
//     });
//   }
// };

export const verify = (uid, token) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ uid, token });

  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/activation/`, body, config);

    dispatch({
      type: ACTIVATION_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: ACTIVATION_FAIL,
    });
  }
};

export const reset_password = (email) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ email });

  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password/`, body, config);

    dispatch({
      type: PASSWORD_RESET_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: PASSWORD_RESET_FAIL,
    });
  }
};

export const set_password = (new_password, re_new_password, current_password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${localStorage.getItem('access')}`,
      Accept: 'application/json',
    },
  };

  const body = JSON.stringify({ new_password, re_new_password, current_password });
  console.log(body);
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/set_password/`, body, config);

    dispatch({
      type: PASSWORD_RESET_SUCCESS,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: PASSWORD_RESET_FAIL,
    });
  }
};

export const reset_password_confirm = (uid, token, new_password, re_new_password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ uid, token, new_password, re_new_password });

  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`, body, config);

    dispatch({
      type: PASSWORD_RESET_CONFIRM_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: PASSWORD_RESET_CONFIRM_FAIL,
    });
  }
};

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

export const save = () => (dispatch) => {
  dispatch({
    type: SAVE_TO_BACKEND,
    payload: 'Success',
  });
};
