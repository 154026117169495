import React from 'react';
export const UsernameField = ({ defaultValue, handleChange }) => {
  return (
    <div className="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
      <label htmlFor="name" className="block text-xs font-medium text-gray-900">
        New Username
      </label>
      <input
        type="text"
        name="username"
        className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export const EmailField = ({ defaultValue, handleChange }) => {
  return (
    <div className="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
      <label htmlFor="email" className="block text-xs font-medium text-gray-900">
        New Email
      </label>
      <input
        type="text"
        name="email"
        className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export const CurrentPasswordField = ({ defaultValue, handleChange }) => {
  return (
    <div className="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
      <label htmlFor="password" className="block text-xs font-medium text-gray-900">
        Verify Password
      </label>
      <input
        type="password"
        name="current_password"
        className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export const NewPasswordField = ({ defaultValue, handleChange }) => {
  return (
    <div className="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
      <label htmlFor="password" className="block text-xs font-medium text-gray-900">
        New Password
      </label>
      <input
        type="password"
        name="new_password"
        className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export const ReEnterNewPasswordField = ({ defaultValue, handleChange }) => {
  return (
    <div className="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
      <label htmlFor="password" className="block text-xs font-medium text-gray-900">
        Re-enter New Password
      </label>
      <input
        type="password"
        name="re_new_password"
        className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export const ToggleProfileAccessField = ({ defaultValue, handleChange }) => {
  return (
    <fieldset className="mt-4" onChange={handleChange}>
      <legend className="sr-only">Notification method</legend>
      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
        <div className="flex items-center">
          <input
            name="is_public"
            type="radio"
            value={true}
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            defaultChecked={defaultValue}
          />
          <label htmlFor="incexp" className="ml-3 block text-sm font-medium text-gray-700">
            Is Public
          </label>
        </div>
        <div className="flex items-center">
          <input
            name="is_public"
            type="radio"
            value={false}
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            defaultChecked={!defaultValue}
          />
          <label htmlFor="incexp" className="ml-3 block text-sm font-medium text-gray-700">
            Is Private
          </label>
        </div>
      </div>
    </fieldset>
  );
};
