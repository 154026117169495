import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../actions/auth';
import TopBar from '../components/TopBar';
import HomeBar from '../components/HomeBar';

const Login = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;
  const [errMsg, setErrMsg] = useState(null);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrMsg(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
    setErrMsg(`Sorry, your password was incorrect.\nPlease double-check your password.`);
  };

  if (isAuthenticated && user) {
    return <Redirect to={`/profile`} />;
  }

  return (
    <>
      <div className="grid grid-cols-2 bg-gray-100">
        <HomeBar />
        <TopBar />
      </div>
      <div className="container mx-auto mt-5 px-10 flex place-content-center">
        <div className="w-full max-w-md">
          <h1 className="text-2xl font-bold text-gray-900">Welcome back!</h1>
          <form className="mt-8" onSubmit={(e) => onSubmit(e)}>
            <div className="relative">
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Email@email.com"
                value={email}
                onChange={(e) => onChange(e)}
                required
                className="peer max-w-xs h-10 w-full border-b-2 border-0 border-gray-300 text-gray-900 placeholder-transparent focus:outline-0 focus:border-blue-600 focus:ring-0"
              />
              <label
                htmlFor="email"
                className="absolute left-0 -top-3.5 text-gray-900 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-900 peer-focus:text-sm"
              >
                Email address
              </label>
            </div>
            <div className="mt-8 relative">
              <input
                id="password"
                type="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => onChange(e)}
                minLength="6"
                required
                className="peer max-w-xs h-10 w-full border-b-2 border-0 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-600 focus:ring-0"
              />
              <label
                htmlFor="password"
                className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-900 peer-focus:text-sm"
              >
                Password
              </label>
            </div>
            <input
              value="Sign in"
              type="submit"
              className="mt-20 px-4 py-2 max-w-xs rounded bg-blue-500 hover:bg-blue-400 text-white font-semibold text-center block w-full focus:outline-none focus:ring focus:ring-offset-2 focus:ring-blue-500 focus:ring-opacity-80 cursor-pointer"
            />
          </form>
          <div className="text-rose-600 whitespace-pre">{errMsg}</div>
          <Link
            to="/reset-password"
            className="mt-4 max-w-xs  block text-sm text-center text-blue-600 hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Reset Password
          </Link>
          <Link
            to="/signup"
            className="mt-4 max-w-xs font-bold block text-sm text-center text-blue-600 hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Sign up
          </Link>
        </div>
      </div>
    </>
  );
};

export default Login;
