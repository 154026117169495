// Generate timeticks given a start_date, end_date, frequency (0 for one-time, 365 for daily, etc) and amount
// Currently all valid time ticks all pay a flat amount

function nextDate(date, frequency) {
  let nextDate = date;
  switch (frequency) {
    case 365:
      nextDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      break;
    case 52:
      nextDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7);
      break;
    case 26:
      nextDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 14);
      break;
    case 24: //needs fixing
      nextDate = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
      break;
    case 12:
      nextDate = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
      break;
    case 4:
      nextDate = new Date(date.getFullYear(), date.getMonth() + 3, date.getDate());
      break;
    case 1:
      nextDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate());
      break;
    default:
      break;
  }
  return nextDate;
}

function RecursivePayoffs(start_date, end_date, frequency, interest, payments, initialValue) {
  // start_date and end_date are dates
  // frequency in (0, 365, 4, 12, 26, 52, 365)
  // interest is a decimal for the rate charged per period (not annualized). e.g. 0.05
  // payments is a list of payoffs. eg:
  // [{date: new Date(2021,0,1), amount: 100},
  // {date: new Date(2021,1,1), amount: 100}]
  // initialValue is a number
  const projection = [{ date: start_date, amount: initialValue }];
  if (frequency !== null && frequency !== 0) {
    let d = nextDate(start_date, frequency);
    let a =
      initialValue * (1 + interest) +
      payments
        .filter((x) => x.date <= d && x.date > start_date)
        .map((item) => item.amount)
        .reduce((prev, next) => prev + next, 0);
    while (d < end_date) {
      projection.push({ date: new Date(d), amount: a });
      a =
        a * (1 + interest) +
        payments
          .filter((x) => x.date > d && x.date <= nextDate(d, frequency))
          .map((item) => item.amount)
          .reduce((prev, next) => prev + next, 0);
      d = nextDate(d, frequency);
    }
  }
  // insert extra payments dates here for extra granularity.
  //  For example if loan has 0 interest but a payoff at d1,
  // insert d1.
  projection.push({ date: end_date, amount: 0 });
  return projection;
}

export default RecursivePayoffs;
