import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password_confirm } from '../actions/auth';
import axios from 'axios';

const ResetPasswordConfirm = ({ match }) => {
  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({
    new_password: '',
    re_new_password: '',
  });

  const { new_password, re_new_password } = formData;
  const [errMsg, setErrMsg] = useState(null);
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setErrMsg(`Updating password. Redirect if successful`);
    if (new_password === re_new_password) {
      const uid = match.params.uid;
      const token = match.params.token;

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const body = JSON.stringify({ uid, token, new_password, re_new_password });

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`,
          body,
          config
        );
        if (res.status == 204) {
          setRequestSent(true);
        }
      } catch (err) {
        setErrMsg(`Error with reset link. Please request another password reset.`);
      }
    } else {
      setErrMsg('passwords do not match');
    }
  };

  if (requestSent) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="container mx-auto mt-5">
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            className="form-control"
            type="password"
            placeholder="New Password"
            name="new_password"
            value={new_password}
            onChange={(e) => onChange(e)}
            minLength="4"
            required
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            type="password"
            placeholder="Confirm New Password"
            name="re_new_password"
            value={re_new_password}
            onChange={(e) => onChange(e)}
            minLength="4"
            required
          />
        </div>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2" type="submit">
          Reset Password
        </button>
        <div className="text-rose-600 whitespace-pre">{errMsg}</div>
      </form>
    </div>
  );
};

export default connect(null, { reset_password_confirm })(ResetPasswordConfirm);
