import _ from 'lodash';
// Pull the latest time tick amount given from an array of javascript objects
// [{date: new Date(...), amount: ...}]
// Returns the amount
function LastPayoff(timesteps, d) {
  const output =
    timesteps.filter((x) => x.date <= d).length > 0
      ? _.maxBy(
          timesteps.filter((x) => x.date <= d),
          'date'
        ).amount
      : 0;
  return output;
}
export default LastPayoff;
