import React from 'react';

const CustomModal = ({ closeModal, children }) => {
  return (
    <>
      {/* Modal backdrop, the gray background */}
      <div
        className="fixed top-0 left-0 w-full h-full overflow-auto bg-gray-500 opacity-75 z-10"
        onClick={closeModal}
      />
      {/* Modal Container containing actual contents*/}
      {/* onClick for modal content should not bubble to the onClick for modal backdrop to close modal */}
      <div
        className="fixed min-w-full md:min-w-[50%] top-1/2 left-1/2 
    -translate-y-1/2 -translate-x-1/2 max-w-full bg-white shadow-lg rounded-lg
     overflow-hidden p-8 md:p-12 z-20"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {children}
      </div>
    </>
  );
};

export default CustomModal;
