import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  ASSETS_LOADED_SUCCESS,
  ASSETS_LOADED_FAIL,
  LIABILITIES_LOADED_SUCCESS,
  LIABILITIES_LOADED_FAIL,
  INCEXP_LOADED_SUCCESS,
  INCEXP_LOADED_FAIL,
  ADD_ASSET,
  ADD_LIABILITY,
  ADD_INCEXP,
  UPDATE_ASSET,
  UPDATE_LIABILITY,
  UPDATE_INCEXP,
  DELETE_ASSET,
  DELETE_LIABILITY,
  DELETE_INCEXP,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  GOOGLE_AUTH_SUCCESS,
  GOOGLE_AUTH_FAIL,
  FACEBOOK_AUTH_SUCCESS,
  FACEBOOK_AUTH_FAIL,
  LOGOUT,
  SAVE_TO_BACKEND,
  VIEW_USER,
} from '../actions/types';
import _ from 'lodash';

const initialState = {
  access: localStorage.getItem('access'),
  refresh: localStorage.getItem('refresh'),
  isAuthenticated: null,
  user: null,
  viewed_user: null,
  assets: [],
  liabilities: [],
  incexp: [],
  trackedUpdates: new Set(),
  trackedDeletes: new Set(),
  trackedAssetUpdates: new Set(),
  trackedLiabilityUpdates: new Set(),
  trackedIncExpUpdates: new Set(),
  trackedAssetDeletes: new Set(),
  trackedLiabilityDeletes: new Set(),
  trackedIncExpDeletes: new Set(),
};

function mergeById(arr) {
  return {
    with: function (arr2) {
      return _.map(arr, (item) => {
        return _.find(arr2, (obj) => obj.external_id === item.external_id) || item;
      });
    },
  };
}

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case VIEW_USER:
      return {
        ...state,
        viewed_user: payload.viewed_user,
      };
    case AUTHENTICATED_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case LOGIN_SUCCESS:
    case GOOGLE_AUTH_SUCCESS:
    case FACEBOOK_AUTH_SUCCESS:
      localStorage.setItem('access', payload.access);
      localStorage.setItem('refresh', payload.refresh);
      return {
        ...state,
        isAuthenticated: true,
        access: payload.access,
        refresh: payload.refresh,
      };
    case SIGNUP_SUCCESS:
    case SIGNUP_FAIL:
    case GOOGLE_AUTH_FAIL:
    case FACEBOOK_AUTH_FAIL:
      return {
        ...state,
        isAuthenticated: false,
      };
    case USER_LOADED_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case AUTHENTICATED_FAIL:
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      return {
        ...state,
        isAuthenticated: false,
      };
    case USER_LOADED_FAIL:
      return {
        ...state,
        user: null,
      };
    case ASSETS_LOADED_SUCCESS:
      return {
        ...state,
        assets: payload.data,
      };
    case ASSETS_LOADED_FAIL:
      return {
        ...state,
        assets: [],
      };
    case LIABILITIES_LOADED_SUCCESS:
      return {
        ...state,
        liabilities: payload,
      };
    case LIABILITIES_LOADED_FAIL:
      return {
        ...state,
        liabilities: [],
      };
    case INCEXP_LOADED_SUCCESS:
      return {
        ...state,
        incexp: payload,
      };
    case INCEXP_LOADED_FAIL:
      return {
        ...state,
        incexp: [],
      };
    case ADD_ASSET:
      return {
        ...state,
        assets: state.assets.concat(payload),
      };
    case ADD_LIABILITY: {
      return {
        ...state,
        liabilities: state.liabilities.concat(payload),
      };
    }
    case ADD_INCEXP:
      return {
        ...state,
        incexp: state.incexp.concat(payload),
      };
    case UPDATE_ASSET:
      return {
        ...state,
        trackedUpdates: payload.id ? state.trackedUpdates.add(payload.id) : state.trackedUpdates,
        trackedAssetUpdates: payload.id ? state.trackedAssetUpdates.add(payload.id) : state.trackedAssetUpdates,
        assets: mergeById(state.assets).with([payload]),
      };
    case UPDATE_LIABILITY:
      return {
        ...state,
        trackedUpdates: payload.id ? state.trackedUpdates.add(payload.id) : state.trackedUpdates,
        trackedLiabilityUpdates: payload.id
          ? state.trackedLiabilityUpdates.add(payload.id)
          : state.trackedLiabilityUpdates,
        liabilities: mergeById(state.liabilities).with([payload]),
      };
    case UPDATE_INCEXP:
      return {
        ...state,
        trackedUpdates: payload.id ? state.trackedUpdates.add(payload.id) : state.trackedUpdates,
        trackedIncExpUpdates: payload.id ? state.trackedLiabilityUpdates.add(payload.id) : state.trackedIncExpUpdates,
        incexp: mergeById(state.incexp).with([payload]),
      };
    case DELETE_ASSET: {
      // Delete the id in trackedUpdates regardless of whether it exists or not
      state.trackedUpdates.delete(payload.id);
      state.trackedAssetUpdates.delete(payload.id);
      return {
        ...state,
        trackedDeletes: payload.id ? state.trackedDeletes.add(payload.id) : state.trackedDeletes,
        trackedAssetDeletes: payload.id ? state.trackedAssetDeletes.add(payload.id) : state.trackedAssetDeletes,
        assets: state.assets.filter((asset) => asset.external_id !== payload.external_id),
      };
    }
    case DELETE_LIABILITY: {
      state.trackedUpdates.delete(payload.id);
      state.trackedLiabilityUpdates.delete(payload.id);

      return {
        ...state,
        trackedDeletes: payload.id ? state.trackedDeletes.add(payload.id) : state.trackedDeletes,
        trackedLiabilityDeletes: payload.id ? state.trackedLiabilityDeletes.add(payload.id) : state.trackedAssetDeletes,
        liabilities: state.liabilities.filter((liability) => liability.external_id !== payload.external_id),
      };
    }
    case DELETE_INCEXP: {
      state.trackedUpdates.delete(payload.id);
      state.trackedIncExpUpdates.delete(payload.id);

      return {
        ...state,
        trackedDeletes: payload.id ? state.trackedDeletes.add(payload.id) : state.trackedDeletes,
        trackedIncExpDeletes: payload.id ? state.trackedIncExpDeletes.add(payload.id) : state.trackedIncExpDeletes,
        incexp: state.incexp.filter((incexp) => incexp.external_id !== payload.external_id),
      };
    }
    case SAVE_TO_BACKEND: {
      state.trackedUpdates.clear();
      state.trackedDeletes.clear();
      state.trackedAssetUpdates.clear();
      state.trackedLiabilityUpdates.clear();
      state.trackedIncExpUpdates.clear();
      state.trackedAssetDeletes.clear();
      state.trackedLiabilityDeletes.clear();
      state.trackedIncExpDeletes.clear();

      return {
        ...state,
      };
    }
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');

      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        user: null,
        viewed_user: null,
        assets: [],
        liabilities: [],
        incexp: [],
        trackedUpdates: new Set(),
        trackedDeletes: new Set(),
        trackedAssetUpdates: new Set(),
        trackedLiabilityUpdates: new Set(),
        trackedIncExpUpdates: new Set(),
        trackedAssetDeletes: new Set(),
        trackedLiabilityDeletes: new Set(),
        trackedIncExpDeletes: new Set(),
      };
    case PASSWORD_RESET_SUCCESS:
    case PASSWORD_RESET_FAIL:
    case PASSWORD_RESET_CONFIRM_SUCCESS:
    case PASSWORD_RESET_CONFIRM_FAIL:
    case ACTIVATION_SUCCESS:
    case ACTIVATION_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
