import React from 'react';
import { useSelector } from 'react-redux';
import { ASSET, LIABILITY, INCEXP } from '../actions/types';

export const AssetEntryForm = ({ item, handleChange, type }) => {
  const { name, amount, frequency, interest, start_date, end_date } = item || {};
  return (
    <>
      <h1 className="py-2 font-bold text-center">
        {item.name} {type}
      </h1>
      <NameField defaultValue={name} handleChange={handleChange} />
      <AmountField defaultValue={amount} handleChange={handleChange} />
      <StartDateField defaultValue={start_date} handleChange={handleChange} />
      {frequency > 0 ? <EndDateField defaultValue={end_date} handleChange={handleChange} /> : ''}
      <FrequencyField defaultValue={frequency} handleChange={handleChange} />
      {frequency > 0 ? (
        <InterestField defaultValue={parseFloat(interest)} handleChange={handleChange} type={type} />
      ) : (
        ''
      )}
      <div className="text-center text-xs">
        {frequency > 0 ? (item.frequency * item.interest).toFixed(2) + '% Annual Percentage Rate' : ''}
      </div>
    </>
  );
};

export const LiabilityEntryForm = ({ item, handleChange, type }) => {
  const { name, amount, frequency, interest, start_date, end_date } = item || {};
  return (
    <>
      <h1 className="py-2 font-bold text-center">
        {item.name} {type}
      </h1>
      <NameField defaultValue={name} handleChange={handleChange} />
      <AmountField defaultValue={amount} handleChange={handleChange} />
      <StartDateField defaultValue={start_date} handleChange={handleChange} />
      {frequency > 0 ? <EndDateField defaultValue={end_date} handleChange={handleChange} /> : ''}
      <FrequencyField defaultValue={frequency} handleChange={handleChange} />
      {frequency > 0 ? (
        <InterestField defaultValue={parseFloat(interest)} handleChange={handleChange} type={type} />
      ) : (
        ''
      )}
      <div className="text-center text-xs">
        {frequency > 0 ? (item.frequency * item.interest).toFixed(2) + '% Annual Percentage Rate' : ''}
      </div>
    </>
  );
};

export const IncomeOrExpenseEntryForm = ({ item, handleChange }) => {
  const { name, amount, frequency, start_date, end_date, related_Asset, related_Liability } = item || {};
  return (
    <>
      <h1 className="py-2 font-bold text-center">
        {item.name} {item.amount > 0 ? 'income' : 'expense'}
      </h1>
      <NameField defaultValue={name} handleChange={handleChange} />
      <AmountField defaultValue={amount} handleChange={handleChange} />
      {frequency > 0 ? (
        <div className="grid grid-cols-2">
          <StartDateField defaultValue={start_date} handleChange={handleChange} />
          <EndDateField defaultValue={end_date} handleChange={handleChange} />
        </div>
      ) : (
        <StartDateField defaultValue={start_date} handleChange={handleChange} />
      )}
      <FrequencyField defaultValue={frequency} handleChange={handleChange} />
      <RelatedAssetField defaultValue={related_Asset} handleChange={handleChange} />
      <RelatedLiabilityField defaultValue={related_Liability} handleChange={handleChange} />
    </>
  );
};

export const EntryTypeFields = ({ item, type, handleChange }) => {
  switch (type) {
    case ASSET:
      return <AssetEntryForm item={item} handleChange={handleChange} type={type} />;
    case LIABILITY:
      return <LiabilityEntryForm item={item} handleChange={handleChange} type={type} />;
    case INCEXP:
      return <IncomeOrExpenseEntryForm item={item} handleChange={handleChange} />;
    default:
      return '';
  }
};

export const SelectEntryTypeField = ({ setDisplayedEntryType }) => {
  return (
    <div>
      <h1>New Entry</h1>
      <ul className="grid grid-cols-2 my-2" onChange={setDisplayedEntryType}>
        <li>
          <input type="radio" id="asset" name="EntryType" value="asset" className="hidden peer" required />
          <label
            htmlFor="asset"
            className="block text-center w-full p-2 text-gray-500 border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            Asset
          </label>
        </li>
        <li>
          <input type="radio" id="liability" name="EntryType" value="liability" className="hidden peer" required />
          <label
            htmlFor="liability"
            className="block text-center w-full p-2 text-gray-500 border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            Liability
          </label>
        </li>
        <li className="col-span-2">
          <input type="radio" id="incexp" name="EntryType" value="incexp" className="hidden peer" required />
          <label
            htmlFor="incexp"
            className="block text-center w-full p-2 text-gray-500 border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            Income or Expense
          </label>
        </li>
      </ul>
    </div>
  );
};

export const NameField = ({ defaultValue, handleChange }) => {
  return (
    <div className="relative border border-gray-300 focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500">
      <label
        htmlFor="entry name"
        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
      >
        Entry Name
      </label>
      <input
        type="text"
        name="name"
        className="w-full border-hidden"
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    </div>
  );
};
export const AmountField = ({ defaultValue, handleChange }) => {
  return (
    <div className="relative border border-gray-300 focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500">
      <label
        htmlFor="amount"
        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
      >
        Amount
      </label>
      <input
        type="number"
        name="amount"
        step="0.01"
        className="w-full border-hidden"
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export const StartDateField = ({ defaultValue, handleChange }) => {
  return (
    <div className="relative border border-gray-300  px-3 py-2 focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500 h-10 flex items-center">
      <label
        htmlFor="date"
        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
      >
        Start Date
      </label>
      <input
        type="date"
        name="start_date"
        className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-xs sm:text-base"
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    </div>
  );
};
export const EndDateField = ({ defaultValue, handleChange }) => {
  return (
    <div className="relative border border-gray-300  px-3 py-2 focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500 h-10 flex items-center">
      <label
        htmlFor="date"
        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
      >
        End Date
      </label>
      <input
        type="date"
        name="end_date"
        className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-xs sm:text-base"
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export const InterestField = ({ defaultValue, handleChange, type }) => {
  return (
    <div className="relative border border-gray-300 focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500">
      <label
        htmlFor="interest"
        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs text-gray-900"
      >
        {type == 'asset' ? 'Growth Rate (%) per period' : 'Interest Rate (%) per period'}
      </label>
      <input
        type="number"
        name="interest"
        step="0.01"
        className="w-full border-hidden"
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    </div>
  );
};

const frequencyFunction = {
  365: 'Daily',
  52: 'Weekly',
  26: 'Biweekly',
  24: 'Semi-monthly',
  12: 'Monthly',
  4: 'Quarterly',
  1: 'Annually',
  0: 'One-time',
};

export const FrequencyField = ({ defaultValue, handleChange }) => {
  return (
    <div className="relative border border-gray-300 focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500">
      <label
        htmlFor="Frequency"
        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
      >
        Frequency
      </label>
      <select className="w-full border-hidden" name="frequency" defaultValue={defaultValue} onChange={handleChange}>
        {[0, 1, 4, 12, 26, 52].map((x) => (
          <option key={x} value={x}>
            {frequencyFunction[x]}
          </option>
        ))}
      </select>
    </div>
  );
};

export const RelatedAssetField = ({ defaultValue, handleChange }) => {
  const assets = useSelector((state) => state.auth.assets);
  return (
    <div className="relative border border-gray-300 focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500">
      <label
        htmlFor="related_Asset"
        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
      >
        Related asset
      </label>
      <select className="w-full border-hidden" name="related_Asset" defaultValue={defaultValue} onChange={handleChange}>
        <option value=""></option>
        {assets
          .filter((x) => x.name.toLowerCase() !== 'cash')
          .map((asset) => (
            <option key={asset.external_id} value={asset.external_id}>
              {asset.name}
            </option>
          ))}
      </select>
    </div>
  );
};

export const RelatedLiabilityField = ({ defaultValue, handleChange }) => {
  const liabilities = useSelector((state) => state.auth.liabilities);
  return (
    <div className="relative border border-gray-300 focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500">
      <label
        htmlFor="related_Liability"
        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
      >
        Related debt
      </label>
      <select
        className="w-full border-hidden"
        name="related_Liability"
        defaultValue={defaultValue}
        onChange={handleChange}
      >
        <option value=""></option>
        {liabilities.map((liability) => (
          <option key={liability.external_id} value={liability.external_id}>
            {liability.name}
          </option>
        ))}
      </select>
    </div>
  );
};
