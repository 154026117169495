import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { load_assets, load_liabilities, load_incexp } from '../actions/auth';
import { VIEW_USER } from '../actions/types';
import HomeBar from '../components/HomeBar';
import TopBar from '../components/TopBar';

export const UserPage = ({ match }) => {
  const username = match.params.username;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: VIEW_USER, payload: { viewed_user: username } });
    dispatch(load_assets(username));
    dispatch(load_liabilities(username));
    dispatch(load_incexp(username));
  }, []);
  const viewed_user = useSelector((state) => state.auth.viewed_user);
  return (
    <>
      <div className="grid grid-cols-2 bg-gray-100">
        <HomeBar />
        <TopBar />
      </div>
      <div className="container mx-auto mt-5 px-2">
        <h1>{username}</h1>
        {viewed_user && (
          <div className="grid">
            <Link
              to={`${match.url}/journal`}
              className="p-2 my-1 hover:bg-gray-100 border-2 rounded-full text-center w-36"
            >
              Journal
            </Link>
            <Link
              to={`${match.url}/trend`}
              className="p-2 my-1 hover:bg-gray-100 border-2 rounded-full text-center w-36"
            >
              Trends
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export const UserNotFound = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return (
    <>
      <TopBar />
      <div>User {urlParams.get('username')} not found</div>
    </>
  );
};
