import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Entry } from '../components/Entry';
import TopBar from '../components/TopBar';
import AppBar from '../components/AppBar';
import BottomBar from '../components/BottomBar';
import { NewEntryModal } from '../components/NewEntryModal';
import _ from 'lodash';
import { ASSET, LIABILITY, INCEXP, VIEW_USER } from '../actions/types';
import { load_assets, load_liabilities, load_incexp, save } from '../actions/auth';
import { IoSaveSharp } from 'react-icons/io5';

const dateConvert = (string_date) => {
  if (string_date == null) return null;
  const parts = string_date.split('-');
  return new Date(parts[0], parts[1] - 1, parts[2]);
};

const Journal = ({ match }) => {
  const URLusername = match.params.username;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const viewed_user = useSelector((state) => state.auth.viewed_user);

  if (URLusername !== viewed_user) {
    console.count(URLusername);
    dispatch({ type: VIEW_USER, payload: { viewed_user: URLusername } });
    dispatch(load_assets(URLusername));
    dispatch(load_liabilities(URLusername));
    dispatch(load_incexp(URLusername));
  }
  const assets = useSelector((state) => state.auth.assets);
  const liabilities = useSelector((state) => state.auth.liabilities);
  const incexp = useSelector((state) => state.auth.incexp);

  const assetUpdates = useSelector((state) => state.auth.trackedAssetUpdates);
  const liabilityUpdates = useSelector((state) => state.auth.trackedLiabilityUpdates);
  const incexpUpdates = useSelector((state) => state.auth.trackedIncExpUpdates);
  const assetDeletes = useSelector((state) => state.auth.trackedAssetDeletes);
  const liabilityDeletes = useSelector((state) => state.auth.trackedLiabilityDeletes);
  const incexpDeletes = useSelector((state) => state.auth.trackedIncExpDeletes);

  const entries = [...assets, ...liabilities, ...incexp];
  const hasNewEntry = entries.find((entry) => !entry.id);
  const changes =
    hasNewEntry ||
    assetUpdates.size ||
    liabilityUpdates.size ||
    incexpUpdates.size ||
    assetDeletes.size ||
    liabilityDeletes.size ||
    incexpDeletes.size;

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [assets, liabilities, incexp, assetUpdates]);
  const alertUser = (e) => {
    if (changes) {
      e.preventDefault();
      e.returnValue = 'You have unsaved changes in your state.';
      console.log(e.returnValue, changes);
    }
  };
  const onSave = () => {
    // Hard-coded writing user id
    const assetToCreate = assets.filter((asset) => !asset.id).map((obj) => ({ ...obj, user: user.id }));
    const liabilityToCreate = liabilities.filter((liab) => !liab.id).map((obj) => ({ ...obj, user: user.id }));
    const incexpToCreate = incexp.filter((incexp) => !incexp.id).map((obj) => ({ ...obj, user: user.id }));

    const assetToDelete = [...assetDeletes];
    const liabilityToDelete = [...liabilityDeletes];
    const incexpToDelete = [...incexpDeletes];

    const assetToUpdate = assets.filter((asset) => assetUpdates.has(asset.id));
    const liabilityToUpdate = liabilities.filter((liability) => liabilityUpdates.has(liability.id));
    const incexpToUpdate = incexp.filter((incexp) => incexpUpdates.has(incexp.id));

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${localStorage.getItem('access')}`,
        Accept: 'application/json',
      },
    };

    const assetPromise = axios.post(
      `${process.env.REACT_APP_API_URL}/app/asset/`,
      { create: assetToCreate, delete: assetToDelete, update: assetToUpdate },
      config
    );

    const liabilityPromise = axios.post(
      `${process.env.REACT_APP_API_URL}/app/liability/`,
      { create: liabilityToCreate, delete: liabilityToDelete, update: liabilityToUpdate },
      config
    );

    const incexpPromise = axios.post(
      `${process.env.REACT_APP_API_URL}/app/incexp/`,
      { create: incexpToCreate, delete: incexpToDelete, update: incexpToUpdate },
      config
    );

    Promise.all([assetPromise, liabilityPromise, incexpPromise]).then(() => {
      dispatch(load_assets(user.username));
      dispatch(load_liabilities(user.username));
      dispatch(load_incexp(user.username));
      dispatch(save());
    });
  };

  // For the add modal button
  const [isNewEntryModalHidden, setIsNewEntryModalHidden] = useState(true);
  const openNewEntryModal = () => {
    setIsNewEntryModalHidden(false);
  };
  const closeNewEntryModal = () => {
    setIsNewEntryModalHidden(true);
  };

  const nextIndex = _.max([..._.map([...assets, ...liabilities, ...incexp], (item) => item.external_id), 0]);
  const NetWorth = _.sumBy(assets, (item) => Number(item.amount)) - _.sumBy(liabilities, (item) => Number(item.amount));
  const CashFlow = _.sumBy(
    incexp.filter((x) => dateConvert(x.start_date) < new Date()),
    (item) => Number(item.amount) * Number(item.frequency)
  );

  return (
    <>
      <div className="grid grid-cols-2 bg-gray-100">
        <AppBar />
        <TopBar />
      </div>
      {/* Add Entry */}
      <button
        className="p-4 rounded-full shadow-sm text-white bg-blue-500 hover:bg-blue-700 fixed bottom-1 right-1 mx-3 mb-12 md:mb-6"
        onClick={openNewEntryModal}
      >
        <svg className="h-6 w-6" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
        </svg>
      </button>
      {/* Save entries if there are changes */}
      {user && URLusername === user.username && !!changes && (
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded fixed top-12 right-3"
          onClick={onSave}
        >
          <IoSaveSharp size="25" />
        </button>
      )}

      {!isNewEntryModalHidden && <NewEntryModal nextIndex={nextIndex} closeModal={closeNewEntryModal} />}
      <div className="grid grid-cols-2 px-2">
        <div className="pt-2 col-span-2">
          {entries.length == 0 ? viewed_user + ' is a private user or has no entries' : 'Entries for: ' + viewed_user}
        </div>
        <div className="col-span-2 md:col-span-1">
          <div className="font-bold pt-2"> Asset</div>
          {assets.map((asset) => (
            <Entry key={asset.external_id} item={asset} type={ASSET} />
          ))}
          <div className="font-bold pt-2 pb-1">Liability</div>
          {liabilities.map((liability) => (
            <Entry key={liability.external_id} item={liability} type={LIABILITY} />
          ))}
        </div>
        <div className="col-span-2 md:col-span-1">
          <div className="font-bold pt-2"> Income or Expense</div>
          {incexp.map((incexp) => (
            <Entry key={incexp.external_id} item={incexp} type={INCEXP} />
          ))}
        </div>
        <div className="h-36" />
      </div>

      <BottomBar NetWorth={NetWorth} CF={CashFlow} />
    </>
  );
};

export default Journal;
