import React from 'react';
import TopBar from '../components/TopBar';
import HomeBar from '../components/HomeBar';
import { IoCaretForward } from 'react-icons/io5';

const TOS = () => {
  return (
    <>
      <div className="grid grid-cols-2 fixed w-full bg-gray-100">
        <HomeBar />
        <TopBar />
      </div>
      <div className="container mx-auto p-2 text-justify">
        <h1 className="text-2xl font-bold pb-3 mt-12">Terms of service</h1>
        <h2 className="pb-3">Last updated: July 20, 2023</h2>
        <div className="py-3">
          <p>We are MyFinanceStory (&apos;Company&apos;, &apos;we&apos;, &apos;us&apos;, or &apos;our&apos;).</p>
          <p>
            We operate the website http://www.myfinancestory.com (the &apos;Site&apos;), as well as any other related
            products and services that refer or link to these legal terms (the &apos;Legal Terms&apos;) (collectively,
            the &apos;Services&apos;).
          </p>
          <p>
            You can contact us by email at <a href="mailto: hello@myfinancestory.com">hello@myfinancestory.com.</a>{' '}
          </p>
          <p>
            These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf
            of an entity (&apos;you&apos;), and MyFinanceStory, concerning your access to and use of the Services. You
            agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these
            Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING
            THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY. Supplemental terms and conditions or documents that
            may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We
            reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms from time
            to time. We will alert you about any changes by updating the &apos;Last updated&apos; date of these Legal
            Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to
            periodically review these Legal Terms to stay informed of updates. You will be subject to, and will be
            deemed to have been made aware of and to have accepted, the changes in any revised Legal Terms by your
            continued use of the Services after the date such revised Legal Terms are posted.
          </p>
        </div>
        <div className="py-2">
          <h2 className="font-bold text-lg">INTELLECTUAL PROPERTY RIGHTS</h2>
          <h3 className="font-bold pt-2">Our intellectual property</h3>
          <p>
            We are the owner or the licensee of all intellectual property rights in our Services, including all source
            code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in
            the Services (collectively, the &apos;Content&apos;), as well as the trademarks, service marks, and logos
            contained therein (the &apos;Marks&apos;). The Content and Marks are provided in or through the Services
            &apos;AS IS&apos; for your personal, non-commercial use only.
          </p>
          <h3 className="font-bold pt-2">Your use of our services</h3>
          <p>
            Subject to your compliance with these Legal Terms, including the{' '}
            <a className="underline" href="#prohibited">
              &apos;PROHIBITED ACTIVITIES&apos;
            </a>{' '}
            section below, we grant you a non-exclusive, non-transferable, revocable licence to access the Services
            solely for your personal, non-commercial use.
          </p>
          <p>
            If you wish to make any use of the Services, Content, or Marks other than as set out in this section or
            elsewhere in our Legal Terms, please address your request to: hello@myfinancestory.com. If we ever grant you
            the permission to post, reproduce, or publicly display any part of our Services or Content, you must
            identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or
            proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
          </p>
          <h3 className="font-bold pt-2">Your submissions</h3>
          <p>
            Submissions: By directly sending us any question, comment, suggestion, idea, feedback, or other information
            about the Services (&apos;Submissions&apos;), you agree to assign to us all intellectual property rights in
            such Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and
            dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to
            you.
          </p>
          <p>
            You are solely responsible for your Submissions and you expressly agree to reimburse us for any and all
            losses that we may suffer because of your breach of (a) this section, (b) any third party’s intellectual
            property rights, or (c) applicable law.
          </p>
          <p>
            We may remove or edit your Content: Although we have no obligation to monitor any Submissions, we shall have
            the right to remove or edit any Submissions at any time without notice if in our reasonable opinion we
            consider such Contributions harmful or in breach of these Legal Terms. If we remove or edit any such
            Contributions, we may also suspend or disable your account.
          </p>
        </div>
        <div className="py-2">
          <h2 className="font-bold text-lg">USER REGISTRATION</h2>
          <p>
            You may be required to register to use the Services. You agree to keep your password confidential and will
            be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change
            a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene,
            or otherwise objectionable.
          </p>
        </div>
        <div className="py-2">
          <h2 className="font-bold text-lg" id="prohibited">
            PROHIBITED ACTIVITIES
          </h2>
          <p>
            You may not access or use the Services for any purpose other than that for which we make the Services
            available. The Services may not be used in connection with any commercial endeavours except those that are
            specifically endorsed or approved by us.
          </p>
          <p>As a user of the Services, you agree not to:</p>
          <ul className="py-2">
            <li className="flex">
              <IoCaretForward size={'20'} />
              <p className="ml-4">
                Systematically retrieve data or other content from the Services to create or compile, directly or
                indirectly, a collection, compilation, database, or directory without written permission from us.
              </p>
            </li>
            <li className="flex">
              <IoCaretForward size={'20'} />
              <p className="ml-4">
                Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account
                information such as user passwords.
              </p>
            </li>
            <li className="flex">
              <IoCaretForward size={'20'} />
              <p className="ml-4">Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services</p>
            </li>
            <li className="flex">
              <IoCaretForward size={'20'} />
              <p className="ml-4">
                Use any information obtained from the Services in order to harass, abuse, or harm another person.
              </p>
            </li>
            <li className="flex">
              <IoCaretForward size={'20'} />
              <p className="ml-4">
                Make improper use of our support services or submit false reports of abuse or misconduct.
              </p>
            </li>
            <li className="flex">
              <IoCaretForward size={'20'} />
              <p className="ml-4">Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services</p>
            </li>
            <li className="flex">
              <IoCaretForward size={'20'} />
              <p className="ml-4">
                Engage in any automated use of the system, such as using scripts to send comments or messages, or using
                any data mining, robots, or similar data gathering and extraction tools.
              </p>
            </li>
          </ul>
        </div>
        <div className="py-2">
          <h2 className="font-bold text-lg">CORRECTIONS</h2>
          <p>
            There may be information on the Services that contains typographical errors, inaccuracies, or omissions,
            including descriptions, pricing, availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or update the information on the Services at
            any time, without prior notice.
          </p>
        </div>
        <div className="py-2">
          <h2 className="font-bold text-lg">DISCLAIMER</h2>
          <p>
            THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL
            BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
            IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES
            OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES&apos; CONTENT OR THE CONTENT OF ANY
            WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR
            ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE,
            OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS
            TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
            THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES,
            TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR
            (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
            RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO
            NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
            BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
            FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
            MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
            PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
            JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
          </p>
        </div>
        <div className="py-2">
          <h2 className="font-bold text-lg">LIMITATIONS OF LIABILITY</h2>
          <p>
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
            DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT,
            LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
            LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE
            LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
            LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
            YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL
            RIGHTS.
          </p>
        </div>
        <div className="py-2">
          <h2 className="font-bold text-lg">INDEMNIFICATION</h2>
          <p>
            You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our
            respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim,
            or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out
            of: (1) your Contributions; (2) use of the Services; (3) breach of these Legal Terms; (4) any breach of your
            representations and warranties set forth in these Legal Terms; (5) your violation of the rights of a third
            party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any
            other user of the Services with whom you connected via the Services. Notwithstanding the foregoing, we
            reserve the right, at your expense, to assume the exclusive defence and control of any matter for which you
            are required to indemnify us, and you agree to cooperate, at your expense, with our defence of such claims.
            We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to
            this indemnification upon becoming aware of it.
          </p>
        </div>
        <div className="py-2">
          <h2 className="font-bold text-lg">USER DATA</h2>
          <p>
            We will maintain certain data that you transmit to the Services for the purpose of managing the performance
            of the Services, as well as data relating to your use of the Services. Although we perform regular routine
            backups of data, you are solely responsible for all data that you transmit or that relates to any activity
            you have undertaken using the Services. You agree that we shall have no liability to you for any loss or
            corruption of any such data, and you hereby waive any right of action against us arising from any such loss
            or corruption of such data.
          </p>
        </div>
        <div className="py-2">
          <h2 className="font-bold text-lg">MISCELLANEOUS</h2>
          <p>
            These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the
            Services constitute the entire agreement and understanding between you and us. Our failure to exercise or
            enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or
            provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of
            our rights and obligations to others at any time. We shall not be responsible or liable for any loss,
            damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part
            of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or
            part of the provision is deemed severable from these Legal Terms and does not affect the validity and
            enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency
            relationship created between you and us as a result of these Legal Terms or use of the Services. You agree
            that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive
            any and all defences you may have based on the electronic form of these Legal Terms and the lack of signing
            by the parties hereto to execute these Legal Terms.
          </p>
        </div>
      </div>
    </>
  );
};

export default TOS;
