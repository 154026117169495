import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import { Provider } from 'react-redux';
import Home from './containers/Home';
import TOS from './containers/TOS';
import Login from './containers/Login';
import Signup from './containers/Signup';
import Activate from './containers/Activate';
import ResetPassword from './containers/ResetPassword';
import ResetPasswordConfirm from './containers/ResetPasswordConfirm';
// import Facebook from './containers/Facebook';
// import Google from './containers/Google';
import Journal from './containers/Journal';
import Profile from './containers/Profile';
import Trend from './containers/Trend';
import { UserPage, UserNotFound } from './containers/UserPage';

import store from './store';

import Layout from './hocs/Layout';

import './styles/output.css';

function App() {
  const { pathname } = useLocation();
  return (
    <Provider store={store}>
      <Layout>
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          {/* <Route exact path="/facebook" component={Facebook} />
          <Route exact path="/google" component={Google} /> */}
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/password/reset/confirm/:uid/:token" component={ResetPasswordConfirm} />
          <Route exact path="/activate/:uid/:token" component={Activate} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/profile/user_not_found" component={UserNotFound} />
          <Route exact path="/profile/:username" component={UserPage} />
          <Route exact path="/profile/:username/journal" component={Journal} />
          <Route exact path="/profile/:username/trend" component={Trend} />
          <Route exact path="/terms" component={TOS} />
        </Switch>
      </Layout>
    </Provider>
  );
}

export default App;
