import React, { useState } from 'react';
import AppBar from '../components/AppBar';
import { useSelector, useDispatch } from 'react-redux';
import { VictoryChart, VictoryVoronoiContainer, VictoryScatter, VictoryLine, VictoryAxis } from 'victory';
import _ from 'lodash';
import RecursivePayoffs from '../FinCalcs/RecursivePayoffs';
import Payoffs from '../FinCalcs/Payoffs';
import LastPayoff from '../FinCalcs/LastPayoff';
import { load_assets, load_liabilities, load_incexp } from '../actions/auth';
import { VIEW_USER } from '../actions/types';
import TopBar from '../components/TopBar';

function dateConvert(string_date) {
  if (string_date == undefined) {
    return undefined;
  }
  const parts = string_date.split('-');
  return new Date(parts[0], parts[1] - 1, parts[2]);
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function dateTick(d, z) {
  return new Date(d.getFullYear(), d.getMonth() + 6 * z, d.getDate());
}

function numFormat(string_num) {
  return (Math.round((Number(string_num) + Number.EPSILON) * 100) / 100).toLocaleString();
}

const Trend = ({ match }) => {
  const dispatch = useDispatch();
  const URLusername = match.params.username;
  const viewed_user = useSelector((state) => state.auth.viewed_user);
  if (URLusername !== viewed_user) {
    dispatch({ type: VIEW_USER, payload: { viewed_user: URLusername } });
    dispatch(load_assets(URLusername));
    dispatch(load_liabilities(URLusername));
    dispatch(load_incexp(URLusername));
  }
  const assets = useSelector((state) => state.auth.assets);
  const liabilities = useSelector((state) => state.auth.liabilities);
  const incexp = useSelector((state) => state.auth.incexp);

  // Amount of timesteps for rendering
  const DateRange = Array.from(Array(20).keys());

  // Choose the cash start_date if it is available. Otherwise use current date.
  const StartDate =
    dateConvert(assets.filter((x) => x.name.toLowerCase() === 'cash').map((y) => y.start_date)[0]) ?? new Date();
  const EndDate = new Date(StartDate.getFullYear() + 15, StartDate.getMonth(), StartDate.getDate()); // Adjust the Projection End Date
  const InitialCash = Number(assets.filter((x) => x.name.toLowerCase() === 'cash').map((y) => y.amount)[0]) ?? 0;

  // Generate grid for IncExp to calculate cash projection
  const IncExpProjection = incexp.flatMap((x) =>
    Payoffs(
      x.start_date !== undefined && x.start_date !== null ? dateConvert(x.start_date) : StartDate,
      x.end_date !== undefined && x.end_date !== null ? new Date(Math.min(dateConvert(x.end_date), EndDate)) : EndDate,
      Number(x.frequency),
      Number(x.amount)
    )
  );
  // Generate grid for Assets
  const AssetProjection = assets
    .filter((x) => x.name.toLowerCase() !== 'cash')
    .map((x) => ({
      key: x.external_id,
      name: x.name,
      start_date: x.start_date == undefined ? '' : x.start_date,
      end_date: x.end_date == undefined ? '' : x.end_date,
      projection: RecursivePayoffs(
        x.start_date !== undefined && x.start_date !== null ? dateConvert(x.start_date) : StartDate,
        x.end_date !== undefined && x.end_date !== null
          ? new Date(Math.min(dateConvert(x.end_date), EndDate))
          : EndDate,
        Number(x.frequency),
        Number(x.interest) / 100,
        incexp
          .filter((y) => Number(y.related_Asset) === Number(x.external_id))
          .flatMap((z) =>
            Payoffs(
              z.start_date !== undefined && z.start_date !== null ? dateConvert(z.start_date) : StartDate,
              z.end_date !== undefined && z.end_date !== null
                ? new Date(Math.min(dateConvert(z.end_date), EndDate))
                : EndDate,
              Number(z.frequency),
              // Asset contributions are positive
              Number(-z.amount)
            )
          ),
        Number(x.amount)
      ),
    }));
  // Generate grid for Liabilities
  const LiabilityProjection = liabilities.map((x) => ({
    key: x.external_id,
    name: x.name,
    start_date: x.start_date == undefined ? '' : x.start_date,
    end_date: x.end_date == undefined ? '' : x.end_date,
    projection: RecursivePayoffs(
      x.start_date !== undefined && x.start_date !== null ? dateConvert(x.start_date) : StartDate,
      x.end_date !== undefined && x.end_date !== null ? new Date(Math.min(dateConvert(x.end_date), EndDate)) : EndDate,
      Number(x.frequency),
      Number(x.interest) / 100,
      incexp
        .filter((y) => Number(y.related_Liability) === Number(x.external_id))
        .flatMap((z) =>
          Payoffs(
            z.start_date !== undefined && z.start_date !== null ? dateConvert(z.start_date) : StartDate,
            z.end_date !== undefined && z.end_date !== null
              ? new Date(Math.min(dateConvert(z.end_date), EndDate))
              : EndDate,
            Number(z.frequency),
            Number(z.amount)
          )
        ),
      Number(x.amount)
    ),
  }));
  // Generate data for plotting
  const CashData = DateRange.map((z) => ({
    x: dateTick(StartDate, z),
    y:
      InitialCash +
      _.sumBy(
        IncExpProjection.filter((x) => x.date <= dateTick(StartDate, z) && x.date > StartDate),
        'amount'
      ),
  }));

  const AssetData = DateRange.map((z) => ({
    x: dateTick(StartDate, z),
    y: _.sum(AssetProjection.map((x) => LastPayoff(x.projection, dateTick(StartDate, z)))),
  }));

  const LiabilityData = DateRange.map((z) => ({
    x: dateTick(StartDate, z),
    y: _.sum(LiabilityProjection.map((x) => LastPayoff(x.projection, dateTick(StartDate, z)))),
  }));

  const NWData = DateRange.map((z) => ({
    x: dateTick(StartDate, z),
    y: CashData[z].y + AssetData[z].y - LiabilityData[z].y,
  }));

  // Plot selector
  const [selectedDate, setSelectedDate] = useState(NWData[2].x.toISOString().split('T')[0]);
  const [selectedIndex, setSelectedIndex] = useState(2);

  const [checkedCash, setCheckedCash] = useState(true);
  const [checkedAssets, setCheckedAssets] = useState(true);
  const [checkedLiabilities, setCheckedLiabilities] = useState(true);
  const [checkedNW, setcheckedNW] = useState(true);

  const handleChangeCash = () => {
    setCheckedCash(!checkedCash);
  };
  const handleChangeAssets = () => {
    setCheckedAssets(!checkedAssets);
  };
  const handleChangeLiabilities = () => {
    setCheckedLiabilities(!checkedLiabilities);
  };
  const handleChangeNW = () => {
    setcheckedNW(!checkedNW);
  };

  return (
    <>
      <div className="grid grid-cols-2 bg-gray-100">
        <AppBar />
        <TopBar />
      </div>
      <div className="grid grid-cols-2 px-2">
        <div className="col-span-2 md:col-span-1 pb-4">
          <h1 className="py-2">Trends for: {viewed_user}</h1>
          <div id="legend" className="flex space-x-2 justify-center">
            <label
              className={classNames(
                checkedCash ? 'bg-blue-600 text-white' : 'bg-inherit',
                'md:px-3 px-1 border-2 border-black rounded-full md:text-base text-xs cursor-pointer transition-all'
              )}
            >
              <input type="checkbox" checked={checkedCash} onChange={handleChangeCash} className="hidden" />
              Cash
            </label>
            <label
              className={classNames(
                checkedAssets ? 'bg-green-700 text-white' : 'bg-inherit',
                'md:px-3 px-1 border-2 border-black rounded-full md:text-base text-xs cursor-pointer transition-all'
              )}
            >
              <input type="checkbox" checked={checkedAssets} onChange={handleChangeAssets} className="hidden" />
              Other Assets
            </label>
            <label
              className={classNames(
                checkedLiabilities ? 'bg-rose-700 text-white' : 'bg-inherit',
                'md:px-3 px-1 border-2 border-black rounded-full md:text-base text-xs cursor-pointer transition-all'
              )}
            >
              <input
                type="checkbox"
                checked={checkedLiabilities}
                onChange={handleChangeLiabilities}
                className="hidden"
              />
              Liabilities
            </label>
            <label
              className={classNames(
                checkedNW ? 'bg-black text-white' : 'bg-inherit',
                'md:px-3 px-1 border-2 border-black rounded-full md:text-base text-xs cursor-pointer transition-all'
              )}
            >
              <input type="checkbox" checked={checkedNW} onChange={handleChangeNW} className="hidden" />
              Net Worth
            </label>
          </div>
          <VictoryChart
            padding={{ top: 10, bottom: 60, left: 70, right: 60 }}
            containerComponent={<VictoryVoronoiContainer />}
          >
            <VictoryAxis label="Date" tickFormat={(x) => new Date(x).getFullYear()} />
            <VictoryAxis dependentAxis />
            {selectedDate && (
              <VictoryLine
                x={() => dateConvert(selectedDate)}
                style={{
                  data: { stroke: '#cbd5e1', strokeWidth: 1 },
                }}
              />
            )}
            {checkedCash && (
              <VictoryLine
                data={CashData}
                style={{
                  data: { stroke: '#2563eb' },
                }}
              />
            )}
            {checkedCash && (
              <VictoryScatter
                data={CashData}
                style={{
                  data: { fill: ({ active }) => (active ? '#1e3a8a' : '#2563eb') },
                }}
                size={5}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onClick: (evt, clickedProps) => {
                        setSelectedDate(CashData[clickedProps.index].x.toISOString().split('T')[0]);
                        setSelectedIndex(clickedProps.index);
                      },
                    },
                  },
                ]}
              />
            )}
            {checkedAssets && (
              <VictoryLine
                data={AssetData}
                style={{
                  data: { stroke: 'green' },
                }}
              />
            )}
            {checkedAssets && (
              <VictoryScatter
                data={AssetData}
                style={{
                  data: { fill: ({ active }) => (active ? '#14532d' : 'green') },
                }}
                size={5}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onClick: (evt, clickedProps) => {
                        setSelectedDate(AssetData[clickedProps.index].x.toISOString().split('T')[0]);
                        setSelectedIndex(clickedProps.index);
                      },
                    },
                  },
                ]}
              />
            )}
            {checkedLiabilities && (
              <VictoryLine
                data={LiabilityData}
                style={{
                  data: { stroke: '#be123c' },
                }}
              />
            )}
            {checkedLiabilities && (
              <VictoryScatter
                data={LiabilityData}
                style={{
                  data: { fill: ({ active }) => (active ? '#7f1d1d' : '#be123c') },
                }}
                size={5}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onClick: (evt, clickedProps) => {
                        setSelectedDate(LiabilityData[clickedProps.index].x.toISOString().split('T')[0]);
                        setSelectedIndex(clickedProps.index);
                      },
                    },
                  },
                ]}
              />
            )}
            {checkedNW && (
              <VictoryLine
                data={NWData}
                style={{
                  data: { stroke: 'black' },
                }}
              />
            )}
            {checkedNW && (
              <VictoryScatter
                data={NWData}
                style={{
                  data: { fill: ({ active }) => (active ? '#424242' : 'black') },
                }}
                size={5}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onClick: (evt, clickedProps) => {
                        setSelectedDate(NWData[clickedProps.index].x.toISOString().split('T')[0]);
                        setSelectedIndex(clickedProps.index);
                      },
                    },
                  },
                ]}
              />
            )}
          </VictoryChart>
        </div>
        <div className="col-span-2 md:col-span-1 px-2">
          <h1 className="md:py-2 pt-6 w-full md:text-center text-lg">Financial projection</h1>
          {selectedDate !== undefined && (
            <>
              <h2 className="py-4">Date: {selectedDate}</h2>

              <div
                key="Cash"
                className="grid grid-cols-2 md:grid-cols-3 text-blue-600 border-black border-2 rounded-xl mb-2"
              >
                <div className="md:col-span-2 p-4">Cash</div>
                <div className="p-4 text-center">{numFormat(CashData[selectedIndex].y)}</div>
              </div>

              <div
                key="Assets"
                className="grid grid-cols-2 md:grid-cols-3 text-green-700 border-black border-2 rounded-xl mb-2"
              >
                <div className="md:col-span-2 p-4">Other assets</div>
                <div className="p-4 text-center">{numFormat(AssetData[selectedIndex].y)}</div>
                {AssetProjection.filter(
                  (x) =>
                    (dateConvert(x.start_date) <= dateConvert(selectedDate) || x.start_date == '') &&
                    (dateConvert(x.end_date) > dateConvert(selectedDate) || x.end_date == '')
                ).map((x) => (
                  <div key={x.key} className="px-4 pb-4 text-sm">
                    <div>{x.name}</div>
                    <div className="col-span-2">{numFormat(LastPayoff(x.projection, dateConvert(selectedDate)))}</div>
                  </div>
                ))}
              </div>

              <div
                key="Liabilities"
                className="grid grid-cols-2 md:grid-cols-3 text-rose-700 border-black border-2 rounded-xl mb-2"
              >
                <div className="md:col-span-2 p-4">Liabilities</div>
                <div className="p-4 text-center">{numFormat(LiabilityData[selectedIndex].y)}</div>
                {LiabilityProjection.filter(
                  (x) =>
                    (dateConvert(x.start_date) <= dateConvert(selectedDate) || x.start_date == '') &&
                    (dateConvert(x.end_date) > dateConvert(selectedDate) || x.end_date == '')
                ).map((x) => (
                  <div key={x.key} className="px-4 pb-4 text-sm">
                    <div>{x.name}</div>
                    <div>{numFormat(LastPayoff(x.projection, dateConvert(selectedDate)))}</div>
                  </div>
                ))}
              </div>

              <div key="Total" className="grid grid-cols-2 md:grid-cols-3 mt-2 border-4 border-black rounded-full">
                <div className="md:col-span-2 p-4 font-bold">Net Worth</div>
                <div className="p-4 text-center font-bold">
                  {numFormat(CashData[selectedIndex].y + AssetData[selectedIndex].y - LiabilityData[selectedIndex].y)}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Trend;
