// Generate payoffs given a start_date, end_date, frequency (0 for one-time, 365 for daily, etc) and amount
// Currently all valid time ticks all pay a flat amount
function Payoffs(start_date, end_date, frequency, amount) {
  const oneDay = 24 * 60 * 60 * 1000;
  const Y = start_date.getFullYear();
  const M = start_date.getMonth();
  const D = start_date.getDate();
  let projection = [];
  if (frequency !== 0) {
    projection = Array.from(Array(Math.ceil(((end_date - start_date) / oneDay / 365) * frequency)).keys());
  }
  switch (frequency) {
    case 0:
      projection = [
        {
          date: start_date,
          amount: amount,
        },
      ];
      break;
    case 365:
      projection = projection.map((z) => ({
        date: new Date(Y, M, D + z),
        amount: new Date(Y, M, D + z) < end_date ? amount : 0,
      }));
      break;
    case 52:
      projection = projection.map((z) => ({
        date: new Date(Y, M, D + 7 * z),
        amount: new Date(Y, M, D + 7 * z) < end_date ? amount : 0,
      }));
      break;
    case 26:
      projection = projection.map((z) => ({
        date: new Date(Y, M, D + 14 * z),
        amount: new Date(Y, M, D + 14 * z) < end_date ? amount : 0,
      }));
      break;
    case 24: //needs fixing
      projection = projection.map((z) => ({
        date: new Date(Y, M + z, D),
        amount: new Date(Y, M + z, D) < end_date ? amount : 0,
      }));
      break;
    case 12:
      projection = projection.map((z) => ({
        date: new Date(Y, M + z, D),
        amount: new Date(Y, M + z, D) < end_date ? amount : 0,
      }));
      break;
    case 4:
      projection = projection.map((z) => ({
        date: new Date(Y, M + 3 * z, D),
        amount: new Date(Y, M + 3 * z, D) < end_date ? amount : 0,
      }));
      break;
    case 1:
      projection = projection.map((z) => ({
        date: new Date(Y + z, M, D),
        amount: new Date(Y + z, M, D) < end_date ? amount : 0,
      }));
      break;
    default:
      projection = [
        {
          date: start_date,
          amount: amount,
        },
      ];
      break;
  }

  return projection;
}

export default Payoffs;
