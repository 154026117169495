import React from 'react';
import { NavLink } from 'react-router-dom';
import { ImHome } from 'react-icons/im';

function AppBar() {
  return (
    <nav className="space-x-2 sm:space-x-4 pl-2 py-2 inline-flex align-middle">
      <NavLink to="/">
        <ImHome size="25" className="hover:text-blue-700 transition duration-300" />
      </NavLink>
      <NavLink to="./journal" activeClassName="font-bold underline underline-offset-4">
        Journal
      </NavLink>
      <NavLink to="./trend" activeClassName="font-bold underline underline-offset-4">
        Trend
      </NavLink>
    </nav>
  );
}

export default AppBar;
