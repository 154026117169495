import React, { useEffect, useState } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
// import { useSwipeable } from "react-swipeable";

export const CarouselItem = ({ children }) => {
  return <div className="inline-flex items-center justify-center w-full">{children}</div>;
};

const Carousel = ({ children, autoSlide = false, autoSlideInterval = 2000 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    if (!autoSlide) return;
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, autoSlideInterval);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  // const handlers = useSwipeable({
  //   onSwipedLeft: () => updateIndex(activeIndex + 1),
  //   onSwipedRight: () => updateIndex(activeIndex - 1)
  // });

  return (
    <div
      // {...handlers}
      className="overflow-hidden "
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <div
        className="whitespace-nowrap transition-transform duration-1000"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {/* {React.Children.map(children, (child) => {
          return React.cloneElement(child);
          // return React.cloneElement(child, { width: '100%' });
        })} */}
        {React.Children.map(children, (child) => {
          return child;
        })}
      </div>
      <div className="flex justify-center">
        <button
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
        >
          <MdChevronLeft size={30} />
        </button>
        {React.Children.map(children, (child, index) => {
          return (
            <div className="flex items-center justify-center gap-2">
              <button
                className={`${index === activeIndex ? 'active' : ''}`}
                onClick={() => {
                  updateIndex(index);
                }}
              >
                {/* {index + 1} */}
                <div
                  className={`
                transition-all w-3 h-3 mx-2 bg-blue-200 rounded-full
                ${activeIndex === index ? 'p-2' : 'bg-opacity-50'}
                `}
                />
              </button>
            </div>
          );
        })}
        <button
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}
        >
          <MdChevronRight size={30} />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
